import React, { useEffect, useState } from "react";
import style from "./Snowflakes.module.scss";

const Snowflakes = () => {
  const [snowflakes, setSnowflakes] = useState([]);

  useEffect(() => {
    // Generate snowflake data
    const numSnowflakes = 120; // Increase the number of snowflakes
    const generatedSnowflakes = Array.from(
      { length: numSnowflakes },
      (_, i) => ({
        id: i,
        size: Math.random() * 9 + 2,
        left: Math.random() * 100,
        animationDuration: Math.random() * 15 + 5,
        animationDelay: Math.random() * 5,
      })
    );
    setSnowflakes(generatedSnowflakes);
  }, []);

  return (
    <div className={style.snowflakeContainer}>
      {snowflakes.map(
        ({ id, size, left, animationDuration, animationDelay }) => (
          <div
            key={id}
            className={style.snowflake}
            style={{
              // width: `${size}px`,
              // height: `${size}px`,
              fontSize: `${size}px`,
              left: `${left}%`,
              animationDuration: `${animationDuration}s`,
              animationDelay: `${animationDelay}s`,
            }}
          >
            ❄
          </div>
        )
      )}
    </div>
  );
};

export default Snowflakes;
