import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import style from "./Header.module.scss";
import { ReactComponent as LogoSVG } from "../../assets/imgs/logo.svg";
import { ReactComponent as NewYearLogoSVG } from "../../assets/imgs/newyearlogo.svg";
import { ReactComponent as AddSVG } from "../../assets/imgs/add.svg";
import { ReactComponent as EditSVG } from "../../assets/imgs/write.svg";
import { ReactComponent as SettingsSVG } from "../../assets/imgs/settings.svg";
import { ReactComponent as LogoutSVG } from "../../assets/imgs/logout.svg";
import { ReactComponent as HamburgerSVG } from "../../assets/imgs/hamburger.svg";
import { Context } from "../../context/Context";
import { ReactComponent as BackSVG } from "../../assets/imgs/back.svg";
import { useLocation } from "react-router-dom";
import useAuth from "../../assets/hooks/useAuth";

const Header = ({ noticesData, isSnowflakeSeason }) => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isOnHome, setOnHome] = useState();

  const location = useLocation();

  const { user, dispatch } = useContext(Context);

  const { isAuthenticated } = useAuth();

  const disableBodyScroll = () => {
    document.body.classList.add("disable-scroll");
  };
  const enableBodyScroll = () => {
    document.body.classList.remove("disable-scroll");
  };
  const toggleNavMenu = () => {
    setIsNavExpanded(!isNavExpanded);

    if (!isNavExpanded) {
      disableBodyScroll();
    } else {
      enableBodyScroll();
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    dispatch({ type: "LOGOUT" });
    window.location.replace("/login");
  };

  useEffect(() => {
    if (location.pathname === "/") {
      setOnHome(true);
    } else {
      setOnHome(false);
    }
  }, [location.pathname]);

  // const [isSnowflakeSeason, setIsSnowflakeSeason] = useState(false);

  // useEffect(() => {
  //   const currentDate = new Date();
  //   const currentYear = currentDate.getFullYear();

  //   const noFlakesSeasonStart = new Date(`${currentYear}-01-08`);
  //   const noFlakesSeasonEnd = new Date(`${currentYear}-12-25`);

  //   const isSeason =
  //     currentDate >= noFlakesSeasonStart && currentDate <= noFlakesSeasonEnd;

  //   setIsSnowflakeSeason(!isSeason);
  // }, []);

  return (
    <>
      <div className={style.headerWrapper}>
        <div className={style.header}>
          <nav className={style.navigation}>
            <Link
              to="/"
              className={style.logo}
              onClick={() => {
                if (isNavExpanded) toggleNavMenu();
              }}
            >
              {isSnowflakeSeason ? <NewYearLogoSVG /> : <LogoSVG />}{" "}
              <span className={style.title}>
                {!isAuthenticated
                  ? "Нижегородское метро"
                  : `Пользователь: ${user.username} [${user.department}]`}
              </span>
            </Link>
            {((isOnHome && !isAuthenticated) || isAuthenticated) && (
              <button className={style.hamburger} onClick={toggleNavMenu}>
                <HamburgerSVG />
              </button>
            )}

            <div
              className={
                isNavExpanded
                  ? `${style.navigationMenu} ${style.expanded}`
                  : style.navigationMenu
              }
            >
              {isOnHome ? (
                <>
                  {!isAuthenticated ? (
                    <ul>
                      <li
                        onClick={(e) => {
                          e && e.preventDefault();
                          if (isNavExpanded) toggleNavMenu();
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                      >
                        <span>Главная</span>
                      </li>
                      {noticesData?.length > 0 && (
                        <li
                          onClick={(e) => {
                            e && e.preventDefault();
                            if (isNavExpanded) toggleNavMenu();
                            const elementToView =
                              document.getElementById("notices");
                            elementToView.scrollIntoView({
                              behavior: "smooth",
                            });
                          }}
                        >
                          <span>Объявления</span>
                        </li>
                      )}

                      <li
                        onClick={(e) => {
                          e && e.preventDefault();
                          if (isNavExpanded) toggleNavMenu();
                          const elementToView = document.getElementById("info");
                          elementToView.scrollIntoView({ behavior: "smooth" });
                        }}
                      >
                        <span>Полезная информация</span>
                      </li>
                      <li
                        onClick={(e) => {
                          e && e.preventDefault();
                          if (isNavExpanded) toggleNavMenu();
                          const elementToView = document.getElementById("news");
                          elementToView.scrollIntoView({ behavior: "smooth" });
                        }}
                      >
                        <span>Новости</span>
                      </li>
                      <li
                        onClick={(e) => {
                          e && e.preventDefault();
                          if (isNavExpanded) toggleNavMenu();
                          const elementToView =
                            document.getElementById("contacts");
                          elementToView.scrollIntoView({ behavior: "smooth" });
                        }}
                      >
                        <span>Контакты</span>
                      </li>
                    </ul>
                  ) : (
                    <ul className={style.adminMenu}>
                      {(user.department === "ВЦ" ||
                        user.department === "А") && (
                        <li
                          className={style.adminButton}
                          onClick={() => {
                            if (isNavExpanded) toggleNavMenu();
                          }}
                        >
                          <Link to="write-notice">
                            <AddSVG />{" "}
                            <span className={style.adminAdd}>
                              Новое объявление
                            </span>
                          </Link>
                        </li>
                      )}
                      {(user.department === "ВЦ" ||
                        user.department === "А") && (
                        <li
                          className={style.adminButton}
                          onClick={() => {
                            if (isNavExpanded) toggleNavMenu();
                          }}
                        >
                          <Link to="/news/write">
                            <AddSVG />{" "}
                            <span className={style.adminAdd}>
                              Новая новость
                            </span>
                          </Link>
                        </li>
                      )}
                      {(user.department === "ВЦ" ||
                        user.department === "А" ||
                        user.department === "УП") && (
                        <li
                          className={style.adminButton}
                          onClick={() => {
                            if (isNavExpanded) toggleNavMenu();
                          }}
                        >
                          <Link to="/vacancies/new">
                            <AddSVG />{" "}
                            <span className={style.adminAdd}>
                              Новая вакансия
                            </span>
                          </Link>
                        </li>
                      )}
                      {(user.department === "ВЦ" ||
                        user.department === "А" ||
                        user.department === "ОТ") && (
                        <li
                          className={style.adminButton}
                          onClick={() => {
                            if (isNavExpanded) toggleNavMenu();
                          }}
                        >
                          <Link to="/labor-protection/new">
                            <AddSVG />{" "}
                            <span className={style.adminAdd}>Новая СОУТ</span>
                          </Link>
                        </li>
                      )}
                      {(user.department === "ВЦ" ||
                        user.department === "А" ||
                        user.department === "УП") && (
                        <li
                          className={style.adminButton}
                          onClick={() => {
                            if (isNavExpanded) toggleNavMenu();
                          }}
                        >
                          <Link to="/training-center">
                            <EditSVG />{" "}
                            <span className={style.adminAdd}>
                              Учебный центр
                            </span>
                          </Link>
                        </li>
                      )}
                      <li
                        className={style.adminButton}
                        onClick={() => {
                          if (isNavExpanded) toggleNavMenu();
                        }}
                      >
                        <Link to="/settings">
                          <SettingsSVG />{" "}
                          <span className={style.adminTxt}>Настройки</span>
                        </Link>
                      </li>
                      <li
                        className={style.adminButton}
                        onClick={() => {
                          handleLogout();
                          if (isNavExpanded) toggleNavMenu();
                        }}
                      >
                        <div className={style.logout}>
                          <LogoutSVG />{" "}
                          <span className={style.adminTxt}>Выйти</span>
                        </div>
                      </li>
                    </ul>
                  )}
                </>
              ) : (
                <>
                  {isAuthenticated ? (
                    <ul className={style.adminMenu}>
                      {(user.department === "ВЦ" ||
                        user.department === "А") && (
                        <li
                          className={style.adminButton}
                          onClick={() => {
                            if (isNavExpanded) toggleNavMenu();
                          }}
                        >
                          <Link to="write-notice">
                            <AddSVG />{" "}
                            <span className={style.adminAdd}>
                              Новое объявление
                            </span>
                          </Link>
                        </li>
                      )}

                      {(user.department === "ВЦ" ||
                        user.department === "А") && (
                        <li
                          className={style.adminButton}
                          onClick={() => {
                            if (isNavExpanded) toggleNavMenu();
                          }}
                        >
                          <Link to="/news/write">
                            <AddSVG />{" "}
                            <span className={style.adminAdd}>
                              Новая новость
                            </span>
                          </Link>
                        </li>
                      )}
                      {(user.department === "ВЦ" ||
                        user.department === "А" ||
                        user.department === "УП") && (
                        <li
                          className={style.adminButton}
                          onClick={() => {
                            if (isNavExpanded) toggleNavMenu();
                          }}
                        >
                          <Link to="/vacancies/new">
                            <AddSVG />{" "}
                            <span className={style.adminAdd}>
                              Новая вакансия
                            </span>
                          </Link>
                        </li>
                      )}
                      {(user.department === "ВЦ" ||
                        user.department === "А" ||
                        user.department === "ОТ") && (
                        <li
                          className={style.adminButton}
                          onClick={() => {
                            if (isNavExpanded) toggleNavMenu();
                          }}
                        >
                          <Link to="/labor-protection/new">
                            <AddSVG />{" "}
                            <span className={style.adminAdd}>Новая СОУТ</span>
                          </Link>
                        </li>
                      )}
                      {(user.department === "ВЦ" ||
                        user.department === "А" ||
                        user.department === "УП") && (
                        <li
                          className={style.adminButton}
                          onClick={() => {
                            if (isNavExpanded) toggleNavMenu();
                          }}
                        >
                          <Link to="/training-center">
                            <EditSVG />{" "}
                            <span className={style.adminAdd}>
                              Учебный центр
                            </span>
                          </Link>
                        </li>
                      )}
                      <li
                        className={style.adminButton}
                        onClick={() => {
                          if (isNavExpanded) toggleNavMenu();
                        }}
                      >
                        <Link to="/settings">
                          <SettingsSVG />{" "}
                          <span className={style.adminTxt}>Настройки</span>
                        </Link>
                      </li>
                      <li
                        className={style.adminButton}
                        onClick={() => {
                          handleLogout();
                          if (isNavExpanded) toggleNavMenu();
                        }}
                      >
                        <div className={style.logout}>
                          <LogoutSVG />{" "}
                          <span className={style.adminTxt}>Выйти</span>
                        </div>
                      </li>
                    </ul>
                  ) : (
                    <div className={style.backBtn}>
                      <Link to="/">
                        <BackSVG /> <span>на главную</span>
                      </Link>
                    </div>
                  )}
                </>
              )}
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Header;
