import style from "./SnowflakesToggle.module.scss";

const SnowflakesToggle = ({
  showSnowflakes,
  handleSnowflakeToggle,
  setShowSnowflakes,
  isSnowflakeSeason,
}) => {
  return (
    isSnowflakeSeason && (
      <div className={style.snowToggle}>
        <div className={style.wrapper}>
          <div class={style.checkbox_item}>
            <label class={style.checkbox_wrap}>
              <input
                type="checkbox"
                name="checkbox"
                class={style.checkbox_inp}
                checked={showSnowflakes}
                onChange={handleSnowflakeToggle}
              />
              <span class={style.checkbox_mark}></span>
            </label>
            <div
              class={style.title}
              onClick={() => setShowSnowflakes((prev) => !prev)}
            >
              {showSnowflakes ? "Снег" : "Без снега"}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default SnowflakesToggle;
